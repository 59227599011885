<script>
  import { useCookies } from "vue3-cookies";
  //import { useStore } from 'vuex'
  import { mapActions} from "vuex";
  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import serverConfig from '@/helpers/config';

  export default {
    setup() {
        const { cookies } = useCookies();
        //const store = useStore();
       
        let tk = 'Bearer '+cookies.get('tk')
        return { cookies, tk};
    },
    page: {
      title: "Dettaglio Categoria",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Dettaglio Categoria",
        items: [{
            text: "Gestione Categorie",
            href: "/ecommerce/categories-list",
          },
          {
            text: "Dettaglio Categoria",
            active: true,
          },
        ],
        item:{},
        dataEdit:{},
        dataInit:{
            type_doc: '',
            code : '',
            name:'',
            content:'',
            image:'',
            lang:'',
            publish:'',
            on_the_homepage:'no',
            status:'init',
        },        
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        itemList: [
        ],
        paginated:{},
        select:'all',
        search:'',
      };
    },
    computed: {

      
    },


    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    
    beforeMount() {
      console.log(this.$route.params.id)
      this.getItem(this.$route.params.id)
      //this.getList();
      if (this.$store.state.auth.currentUser){
        console.log(this.$store.state.auth.currentUser.email)
      }
      
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),
      SearchData(){
        if (this.select != ''){
          this.getList()
        }
      },
      gotTo(name,id) {
        document.getElementById("edtclosemodal").click();
        this.$router.push({name:name, params:{id: id}})
      },
      getParent(){
        this.getList()
        this.selectDataEdit()
      },
      getList(){
       axios.get(`${this.UrlServer}categories/per-page/group/all`, 
        
        { 
          params: { 
            groupId:this.item._id,
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        this.itemList = response.data.results.data;
        this.paginated = response.data.results
        }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
              // this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

        });
      },
      getItem(id){
       axios.get(`${this.UrlServer}categories/${id}`, 
        { 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
          this.item = response.data
          this.getList()
        }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
              // this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
        });
      },

      editdata(data) {
        let result = this.itemList.findIndex(
          (index) => index._id == data._id
        );
       
       
        document.getElementById("edtidfield1").value =
          this.itemList[result]._id;
         this.dataEdit = this.itemList[result]
      },
      initItem(){
        this.dataInit.code = this.item.code 
        this.dataInit.type_doc = 'child' 
        this.dataInit.parent = this.item._id 
        axios.post(`${this.UrlServer}categories/register`,this.dataInit, {headers: { authorization:this.tk}} )
          .then(()=>{
             this.sortRes = 'desc' 
             this.getList()
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
      selectDataEdit(){
        if (this.cookies.get('brand-search-form')) {
          let id = this.cookies.get('brand-search-form')
          this.getItem(id)
        }
      },
      updateItem() {

        axios.put(`${this.UrlServer}categories/${this.item._id}`, this.item, 
         {headers: { authorization:this.tk}} )
          .then(() => {
           
          }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          });
      },
      updatedata() {
        let result = this.itemList.findIndex(
          (o) => o._id == document.getElementById("edtidfield1").value
        );
        this.dataEdit = this.itemList[result]
        this.dataEdit.scontoPercBase = this.item.scontoPercBase
        this.dataEdit.marca = this.item.marca
        this.dataEdit.marcaID = this.item.marcaID
        this.dataEdit.CodMarca = this.item.CodMarca
        this.dataEdit.scontoPercBase = this.item.scontoPercBase
        
        axios.put(`${this.UrlServer}categories/${document.getElementById('edtidfield1').value}`, this.dataEdit, 
         {headers: { authorization:this.tk}} )
          .then(() => {
            document.getElementById("edtclosemodal").click();
            Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
          }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          });
      },
      deletedata(event) {
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.value) {
            this.itemList.splice(this.itemList.indexOf(event), 1);
            try {
              axios.delete(`${this.UrlServer}categories/${event._id}`)
                .then(() => {
                  
                  this.getList()
                  Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                }).catch((error) => {
                  if (error == "Error: Request failed with status code 401")
                  {
                    this.alertSessionExp()
                  } else {
                    Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
                  }
                });
            }catch(error){
            
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          }
        });
      },
      async alertSessionExp() {
        this.clearSessionStorage()

      },
      setPages() {
        let numberOfPages = Math.ceil(this.itemList.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(itemList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return itemList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      slugify()
      { 
        this.dataEdit.CodiceGruppo = this.dataEdit.name.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'').trim()
        this.updatedata()
      },


    },
    mounted() {
    
    },
    components: {
      Layout,
      PageHeader,
    },
  };
</script>
<template>
  <Layout>
    <PageHeader :title="title+':  '+item.code+' '+item.name" :items="items" />
     <div class="row  mb-50">
      <div class="col-lg-12">
        <div class="card">
           <div class="row">
            <div class="col-lg-2 col-md-12">
              <div class="mb-3 ml-20 pl-20 pt-10">
                <label for="code" class="form-label">Codice </label>
                  <input type="text" id="code" v-model="item.code" class="form-control" placeholder="Inserisci Codice" @blur="updateItem" />
              </div>
            </div>
           </div>
           <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="mb-3 ml-20 pl-20 pt-10">
                <label for="name" class="form-label">Nome</label>
                  <input type="text" id="name" v-model="item.name" class="form-control" placeholder="Inserisci Nome"  @blur="updateItem" />
              </div>
            </div>
           </div>              
           <div class="row  mb-50">
            <div class="col-lg-4 col-md-12">
              <div class=" mb-50 ml-20 pl-20 pt-10">
                <label for="content" class="form-label">Descrizione</label>
                  <textarea id="content" v-model="item.content" class="form-control" placeholder="Inserisci Descrizione"  @blur="updateItem" />
              </div>
            </div>
           </div> 
           <div class="row">
            <div class="col-lg-1 col-md-12">

            </div>
           </div> 
           <div class="row">
            <div class="col-lg-4 col-md-12">

            </div>
           </div>
        </div>
      </div>
     </div>
    <div class="row">
      <div class="col-lg-12 mb-50">
        <div class="card" id="itemList">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                
                  <h5 class="card-title mb-0">{{ $t("t-categories-group") }}</h5>

                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <button type="button" class="btn btn-success add-btn me-1" data-bs-toggle="modal" id="create-btn" @click="initItem">
                    <i class="ri-add-line align-bottom me-1"></i> Aggiungi Categoria associata
                  </button>
                  <button type="button" class="btn btn-info">
                    <i class="ri-file-download-line align-bottom me-1"></i>
                    Importa
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom">
            <form>
              <div class="row g-3">  
                 <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="search-box mb-3">
                  <label for="choices-publish-status-input" class="form-label">Cerca:</label>
                    <input type="text" v-model="search" class="form-control search" placeholder="Cerca Categoria in base alle voci in colonna" @input="getList()" />
                    <i class="ri-search-line search-icon pt-25"></i>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                </div>              
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="itemTable">
                  <thead class="table-light text-muted">
                    <tr>
                      <th class="sort" data-sort="item_code">Codice Categoria</th>
                      <th class="sort" data-sort="item_name">Nome</th>
                      <th class="sort" data-sort="item_desc">Descrizione</th>
                      <th class="sort pl-50" data-sort="action" colspan="3">Azioni</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of itemList" :key="index">
                     
                      <td class="id " style="display: none">
                        <a href="javascript:void(0);" class="fw-medium link-primary">{{ item._id }}</a>
                      </td>
                      <td class="code pl-5">{{ item.code }}</td>
                      <td class="Nome pl-5">{{ item.name }}</td>
                      <td class="Descrizione">{{ item.content }}</td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Remove">
                            <a class="text-danger d-inline-block remove-item-btn" @click="deletedata(item)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                        </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Edit">
                            <a href="#editmodal" data-bs-toggle="modal"
                              class="text-primary d-inline-block edit-item-btn" @click="editdata(item)">
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          
                        </ul>
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Dettaglio scheda">
                            <a href="javascript:void(0)" data-bs-toggle="modal"
                              class=" d-inline-block edit-item-btn" @click="gotTo('category-detail', item._id)">
                              <i class="ri-edit-box-line fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <label class="pt-2">Risultati per pagina:</label>
                <div class="pl-10 pr-20">
                
                <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                  <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                </select>
                </div>

                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                    Precedenti
                  </a>
                  <ul class="pagination listjs-pagination mb-0" >
                    <li :class="{
                              active: index == page,
                              disabled: index == '...',
                            }" v-for="index in paginated.total_pages" :key="index">
                         <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                    </li>
                  
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                   Successivi
                  </a>
                </div>
                

              </div>
            </div>

            <div class="modal fade" id="editmodal"   tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Aggiorna Categoria associata
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                      </div>
                      <div class="mb-3">
                        <label for="ragSoc" class="form-label">Codice Categoria</label>
                        <input type="text" id="ragSoc" v-model="dataEdit.code" class="form-control" placeholder="Inserisci Codice"
                          required />
                      </div>
                      <div class="mb-3">
                        <label for="partitaIva" class="form-label">Nome</label>
                        <input type="text" id="partitaIva" v-model="dataEdit.name" class="form-control" placeholder="Inserisci Nome Categoria"
                          required />
                      </div>
                      <div class="mb-3">
                        <label for="customername-field" class="form-label">Descrizione</label>
                        <textarea type="text" id="codeDest" v-model="dataEdit.content" class="form-control" placeholder="Inserisci Descrizione"
                          required ></textarea>
                      </div> 
                      <div class="mb-3">
                        <a href="javascript:void(0)" @click="gotTo('category-detail', dataEdit._id)">
                            >> Impostazioni avanzate
                        </a>               
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="edtclosemodal" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                        <button type="button" class="btn btn-success" @click="updatedata">
                          Aggiorna
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>